<template>
  <div
    v-if="timeTips"
    class="coupon-countdown"
    :class="{ 'coupon-countdown__row': directionType == 'row' }"
  >
    <span 
      v-if="text === ''"
      class="coupon-countdown__txt"
    >
      {{
        directionType == 'row'
          ? template('', language.SHEIN_KEY_PWA_23548)
          : language.SHEIN_KEY_PWA_23547
      }}
    </span>
    <span 
      v-else 
      :class="textBold ? 
        'coupon-countdown__txt_bold' : 
        'coupon-countdown__txt'"
    >
      {{ text }}
    </span>
    <div
      class="coupon-countdown__time"
      v-html="timeTips"
    ></div>
  </div>
</template>

<script>
import { template, CountDown, debounce } from '@shein/common-function'
export default {
  name: 'CouponCountdown',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    },
    directionType: { // 多档券排列顺序
      type: String,
      default: 'column'
    },
    text: {
      type: String,
      default: ''
    },
    textBold: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      beginTime: 0,
      countTimer: new CountDown(),
      countdown: null
    }
  },
  computed: {
    timeTips () {
      const { H, M, S } = this.countTimer?.timeObj || {}
      let spanTxt = txt => `<span>${txt}</span>`
      return spanTxt(H) + ':' + spanTxt(M) + ':' + spanTxt(S)
    }
  },
  created () {
    this.init()
  },
  methods: {
    template,
    init () {
      // 倒计时开始时间
      if (this.item?.countdownTime) {
        this.beginTime = this.item?.countdownTime
      } else {
        this.beginTime = this.item.end_date * 1000 - new Date().getTime()
        if (this.beginTime <= 0) {
          this.beginTime == 0
        }
      }

      // 初始化倒计时
      this.countTimer.start({
        seconds: this.beginTime / 1000,
        endFunc: debounce({
          func: () => {
            this.$emit('countdown-over')
          }
        })
      })
    }
  }
}
</script>

<style lang="less">
.coupon-countdown {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 400;
  color: var(--themeColor, #FA6338);
  /*rw:begin*/
  color: #222222;
  &__row {
    flex-direction: row;
    align-items: center;
    /*rw:begin*/
    color: var(--themeColor, #666666);
    .coupon-countdown__txt {
      .margin-r(.0533rem);
    }
    .coupon-countdown__txt_bold {
      font-weight: 700;
      font-size: 16px;
    }
  }
  &__txt {
    color: var(--themeColor, #FA6338);
  }
  &__time {
    display: flex;
    align-items: center;
    span {
      flex: 0 0 auto;
      width: .4267rem;
      height: .4rem;
      line-height: .4rem;
      color: #fff;
      background-color: var(--themeColor, #FA6338);
      text-align: center;
      margin: 0 .0533rem;
      border-radius: 2px;
      /*rw:begin*/
      background-color: var(--themeColor, #222222);  
    }
  }
}
</style>
