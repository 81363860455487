<template>
  <section class="coupon-main">
    <!-- 指定运输方式减运费券可叠加提示语 -->
    <p v-if="showStackableTips && item.apply_for == 9">
      {{ language.SHEIN_KEY_PWA_29039 }}
    </p>
    <!-- （商详页范围内）减运费券可叠加提示语 -->
    <p v-if="isForDetail && isStackable">
      {{ language.SHEIN_KEY_PWA_29124 }}
    </p>
    <!-- 优惠券券码 -->
    <p 
      v-if="!isNewStyle && showCouponCode && item.coupon"
      tabindex="0" 
      class="coupon-item"
    >
      <!-- <span>{{ language.SHEIN_KEY_PWA_16658 }}: {{ (item.coupon || "").toUpperCase() }}</span> -->
      <span v-if="incouponpkg">{{ language.SHEIN_KEY_PWA_16658 }}: {{ (expanded ? afterCode : item.coupon || "").toUpperCase() }}</span>
      <span v-else>{{ language.SHEIN_KEY_PWA_16658 }}: {{ (item.coupon || "").toUpperCase() }}</span>
      <span
        v-if="item.is_bind === 0"
        v-expose="getCopyAnalysis('1-6-1-145')"
        v-tap="getCopyAnalysis('1-6-1-144')"
        :data-clipboard-text="(item.coupon || '').toUpperCase()"
        class="copy-code-btn" 
        @click="copyCode"
      >
        {{ language.SHEIN_KEY_PWA_25296 }}
      </span>
    </p>


    <p 
      v-if="tagList.length"
      class="coupon-tag-list"
    >
      <span
        v-for="(tag, index) in tagList" 
        :key="tag + '-' + index"
        class="coupon-tag-item"
      >
        {{ tag }}
      </span>
    </p>

    <!-- 实际抵扣金额 -->
    <p
      v-if="showCouponRealDiscount && realDiscountPriceText"
      tabindex="0"
    >
      <span v-html="realDiscountPriceText"></span>
      <i
        v-if="showGoodsIcon"
        class="goods-icon-tips suiiconfont sui_icon_doubt_15px_2"
        @click.stop="isShowGoodsHandle"
      ></i>
    </p>
    <template v-else>
      <p
        v-if="couponTips" 
        class="coupon-tips"
        v-html="couponTips"
      >
      </p>
    </template>
    <div
      v-if="showProgress && progressCount < 100"
      class="couponp-progress"
    >
      <div
        class="couponp-progress-bg"
        :style="{ width: `${progressCount}%` }"
      ></div>
    </div>

    <!-- 最大抵扣金额 -->
    <p
      v-if="showCouponMaxDiscount && maxDiscountPriceText"
      v-html="maxDiscountPriceText"
    ></p>

    <!-- 下单页可叠加标识已移至 couponSubTitle 中 -->
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import { template } from '@shein/common-function'
import Clipboard from 'clipboard'
import { couponTagHandleMap } from './utils'

export default {
  name: 'CouponInfo',
  props: {
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    showCouponRealDiscount: { // 是否展示实际抵扣金额
      type: Boolean,
      default: false,
    },
    showCouponMaxDiscount: { // 是否展示最大抵扣金额
      type: Boolean,
      default: false
    },
    showGoodsIcon: { // 是否展示可用商品提示icon
      type: Boolean,
      default: false
    },
    couponTips: {
      type: String,
      default: '',
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    // 商详用 - 是否展示New User Gitfs标识 abt
    abtNewUserGifts: {
      type: Boolean,
      default: false,
    },
    incouponpkg: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: [String, Number],
      default: ''
    },
    showCouponCode: { // 是否展示优惠券券码
      type: Boolean,
      default: true,
    },
    isNewStyle: {
      // 是否新样式
      type: Boolean,
      default: false,
    },
    // 是否展示可叠加提示语
    showStackableTips: {
      type: Boolean,
      default: false,
    },
    //（商详页范围内）减运费券可叠加提示语
    isForDetail: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      afterCode: ''
    }
  },
  computed: {
    ...mapGetters('newProductDetail', ['fsAbt']),
    // 实际抵扣金额
    realDiscountPriceText () {
      let result = ''
      const { real_discount_price = {}, limit_price = {}, need_price = {} } = this.item
      if (!real_discount_price || !need_price) return result

      if (+need_price?.amount > 0) {
        // 优惠券未满足门槛
        result = this.template(
          `<em class="discount-price-unreached">${need_price.amountWithSymbol}</em>`,
          this.language.SHEIN_KEY_PWA_17190
        )
      } else {
        // 优惠券已满足门槛
        if (+real_discount_price?.amount > 0) {
          const tpl = `<em class="discount-price">${real_discount_price.amountWithSymbol}</em>`
          if ((!limit_price?.amount) || (+real_discount_price?.amount < +limit_price?.amount)) {
            // 未达到最大抵扣金额
            result = this.template(
              tpl,
              this.language.SHEIN_KEY_PWA_17189
            )
          } else {
            // 达到最大抵扣金额
            result = this.template(
              tpl,
              this.language.SHEIN_KEY_PWA_21449
            )
          }
        }
      }
      return result
    },
    // 最大抵扣金额
    maxDiscountPriceText () {
      let result = ''
      if (this.item?.limit_price?.amount && +this.item?.limit_price?.amount > 0) {
        result = this.template(
          this.item?.limit_price?.amountWithSymbol,
          this.language.SHEIN_KEY_PWA_21450
        )
      }
      return result
    },
    progressCount() {
      const { need_price = {}, coupon_rule = [], satisfied_range } = this.item
      const is_0ThresholdCoupon = satisfied_range == '0' && parseFloat(need_price?.amount) <= 0
      if (is_0ThresholdCoupon) return 0
      if (need_price?.amount <= 0) { return 100 }
      return parseInt((1 - (need_price.amount || 0) / (coupon_rule[0]?.min?.amount)) * 100)
    },
    tagList({ language }) {
      let result = Object.values(couponTagHandleMap).sort(
        (a, b) => a.sort - b.sort
      ).filter(
        ({ check, langKey }) => check(this) && language?.[langKey]
      ).map(({ langKey }) => language[langKey])
      return result
    },
    // 判断免邮券是否可叠加
    isStackable(){
      return this.item.apply_for == 9 && [53, 55, 61].includes(+this.item.real_type_id) && this.fsAbt?.shippingcouponstack?.param?.shippingcouponstackswitch === 'on'
    }
  },
  watch: {
    showGoodsIcon (val) {
      if (val) this.$emit('goods-icon-change')
    }
  },
  mounted(){
    let beforeExpandCouponCode = window.localStorage.getItem('beforeExpandCouponCode')
    let realBind = JSON.parse(window.localStorage.getItem('realBind'))
    // 如果是点了膨胀，显示膨胀后的券码
    if(beforeExpandCouponCode && realBind) {
      let storageAfterCode = window.localStorage.getItem('afterCodes') // JSON.stringify(Array.from(afterCodes.entries()))
      this.afterCode = new Map(JSON.parse(storageAfterCode)).get(this.item.coupon) || this.item.coupon
    }else {
      this.afterCode = this.item.coupon
    }
  },
  methods: {
    template,
    isShowGoodsHandle () {
      this.$emit('is-show-goods-handle')
    },
    copyCode(){
      const clipboard = new Clipboard(`.copy-code-btn`)
      clipboard.on('success', () => {
        const tip = this.template(
          this.item.coupon || '',
          this.language.SHEIN_KEY_PWA_24956
        )
        this.$toast(tip, 3000)
        clipboard.destroy()
      })
    },
    getCopyAnalysis(id){
      return {
        id,
        data: {
          location: 0,
          id: this.item.coupon
        }
      }
    }
  }
}
</script>

<style lang="less">
.coupon-main {
  width: 100%;
  .copy-code-btn{
    color: #2D68A8;
    padding-left: 0.213rem;
    flex: 1;
    text-align: left;
  }
  .coupon-tag-list{
    .coupon-tag-item{
      font-size: 10px;
      padding: 0.0533rem 0.1067rem;
      background: var(--themeColor, @sui_color_welfare);
      color: #FFFFFF;
      margin-right: 0.1067rem;
      display: inline-block;
    }
  }
}
.goods-icon-tips {
  color: #959595;
  .margin-l(.106667rem);
}
.coupon-tips {
  span {
    color: @sui_color_promo_dark;
  }
}
.couponp-progress {
  width: 100%;
  height: .08rem;
  background-color: @sui_color_gray_light2;
  border-radius: 2px;
  margin: .1067rem 0;
}
.couponp-progress-bg {
  height: .08rem;
  background-color: @sui_color_main;
  border-radius: 2px;
  transition: width .5s linear;
}
</style>
