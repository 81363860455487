<template>
  <div
    v-if="categories.length"
    ref="couponCategoriesRef" 
    class="coupon-categories"
    :class="{
      'coupon-categories_collapsed': collapsed,
    }"
  >
    <span 
      v-for="(category, catIndex) in categories.slice(0, lastDisplayedIndex + 1)"
      :key="catIndex"
      class="coupon-categories__item"
    >
      {{ category }}
    </span>
    <span
      v-if="!collapsed || (collapsed && lastDisplayedIndex < categories.length - 1)"
      class="coupon-categories__item coupon-categories__icon"
    >
      <span  
        class="coupon-categories__icon_click"
        @click.stop="handleCollapseToggle()"
      ></span>
      <Icon
        v-show="collapsed"
        name="sui_icon_more_down_16px_2" 
        size="10px" 
      />
      <Icon
        v-show="!collapsed"
        name="sui_icon_more_up_16px_2" 
        size="10px" 
      />
    </span>
  </div>
</template>
<script>
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'

export default {
  components: { Icon },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      categories: [],
      collapsed: true,
      // 折叠后最后一个显示的标签 index（不包括折叠标签）
      lastDisplayedIndex: 999,
      lastHeight: null,
      toggle: false
    }
  },
  mounted() {
    this.initCategories()
    this.$nextTick(() => {
      this.bindResizeObserver()
    })
  },
  methods: {
    template,
    initCategories() {
      const categories = []
      this.item.category_name.forEach(item => {
        if (item.children.length > 0) {
          item.children.forEach(child => {
            categories.push(`${item.cat_name_multi}-${child.cat_name_multi}`)
          })
        } else {
          categories.push(item.cat_name_multi)
        }
      })
      this.categories = [...categories]
      this.lastDisplayedIndex = categories.length - 1
    },
    bindResizeObserver() {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const currentHeight = entry.contentRect.height
          // 手动折叠展开引发的尺寸变更
          if (this.toggle) {
            this.toggle = false
            // 更新尺寸
            this.lastHeight = currentHeight
            return
          }
          // 位置不够导致的换行
          if (currentHeight > this.lastHeight) this.handleCollapsedResize()
          // 更新尺寸
          this.lastHeight = currentHeight
        }
      })
      resizeObserver.observe(this.$refs.couponCategoriesRef)
    },
    handleCollapsedResize(rehandle) {
      const { couponCategoriesRef } = this.$refs
      if (!couponCategoriesRef || !couponCategoriesRef.children.length) return
      const rowHeight = couponCategoriesRef.children[0].clientHeight
      const marginTop = couponCategoriesRef.children[0].offsetTop
      if (couponCategoriesRef.offsetHeight > rowHeight + marginTop) {
        const displayedChildren = [...couponCategoriesRef.children].slice(0, this.lastDisplayedIndex + 2)
        const index = displayedChildren.findIndex(child => child.offsetTop > marginTop)
        if (index === 0) {
          this.lastDisplayedIndex = index
        } else if (index > 0) {
          this.lastDisplayedIndex = rehandle ? this.lastDisplayedIndex - 1 : index - 1
          // 折叠标签因不够位置而换行时需要重新计算
          if(!rehandle) this.$nextTick(() => { this.handleCollapsedResize(true) })
        }
      }
    },
    isCollapseIcon(index) {
      return this.collapsed && index === this.lastDisplayedIndex
    },
    handleCollapseToggle() {
      this.toggle = true
      this.collapsed = !this.collapsed
      if (this.collapsed) {
        // 折叠时需要重新计算折叠标签的位置
        this.handleCollapsedResize()
      } else {
        // 展开
        this.lastDisplayedIndex = this.categories.length - 1
      }
    }
  }
}
</script>
<style lang="less" scoped>
.coupon-categories {   
  position: relative; 
  display: flex;
  margin-left: -8/75rem;
  flex-wrap: wrap;
  width: 100%;
  &__item {
    max-width: calc(100% - .7rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 1px;
    background: fade(@sui_color_welfare, 10%);
    padding: 0 8/75rem;
    margin: 12/75rem 0 0 8/75rem;
    color: @sui_color_welfare_dark;
    .font-dpr(20px);
    font-weight: 400;
    white-space: nowrap;
    height: 30/75rem;
    line-height: 30/75rem;
  }

  &__icon {
    text-align: center;
    padding: 0;
    width: 30/75rem;
    height: 30/75rem;
    position: relative;

    &_click {
      text-align: center;
      width: 40/75rem;
      height: 40/75rem;
      position: absolute;
      top: -5/75rem;
      left: -5/75rem;
    }
  }
}
</style>
