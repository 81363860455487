<template>
  <!-- 券即将过期和即将开始标识 -->
  <span
    v-if="getCouponType"
    class="tag-content"
    :class="{ 'tag-content_row': headType == 'head-row' ,'revisit-tag-content':couponType == 'revisit-coupon'}"
    :style="{color:IS_RW?'#FC4070':'#C44A01'}"
  >
    <!-- item.timeStatus 1: 即将开始 | 2: 即将过期 -->
    {{
      item.timeStatus === "1"
        ? language.SHEIN_KEY_PWA_15971
        : item.timeStatus === "2"
          ? htmlDecode({ text: language.SHEIN_KEY_PWA_15818 })
          : ""
    }}
  </span>
</template>

<script>
import { htmlDecode } from '@shein/common-function'
const { IS_RW } = gbCommonInfo

export default {
  name: 'CheckAndTime',
  props: {
    item: {
      type: Object,
      default: null,
    },
    language: {
      type: Object,
      default: null,
    },
    headType: { // 券展示形式
      type: String,
      default: ''
    },
    couponType: {
      // 弹窗类型，用户回归：revisit-coupon
      type: String,
      default: ''
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
  },
  data: ()=>{
    return {
      IS_RW
    }
  },
  computed: {
    // 获取当前弹窗类型，普通弹窗、用户回归弹窗
    getCouponType() {
      return this.couponType == 'revisit-coupon' ? ['2'].includes(this.item.timeStatus) : this.item.selfStatus === '0' && ['1', '2'].includes(this.item.timeStatus)
    },
  },
  methods: {
    htmlDecode
  }
}
</script>

<style lang="less">
.tag-content_row {
  color: @sui_color_highlight;
  .margin-l(.11rem);
}

.revisit-tag-content{
  font-family: 'SF UI Display';
  .font-dpr(24px);
}
</style>
