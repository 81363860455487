var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"title-section",class:[_vm.couponType == 'revisit-coupon' && 'revisit-sub-title', _vm.isNewUser && 'is-new-user']},[_c('div',{staticClass:"coupon-title-warp",class:{
      'fade-in-down': _vm.item.expandStatus == 'seal'
    }},[_c('div',{staticClass:"coupon-title__text",style:({
        '--text-indent': !_vm.showWraperImg ? 0 : ''
      })},[(_vm.item.real_type_id == '55' || _vm.item.real_type_id == '59')?_c('i',{staticClass:"icon_saver",style:({
          background: `url(${_vm.PUBLIC_CDN}/pwa_dist/images/coupon_box/${_vm.isExpired ? 'sui_icon_saver_disable-39f4cc3b3d' : 'icon_saver-a30c7d2571'}.png)`,backgroundSize: 'cover'
        })}):_vm._e(),_vm._v(" "),((_vm.showWraperImg && _vm.item.real_type_id == '53') || _vm.item.real_type_id == '58')?_c('i',{staticClass:"wraper-img suiiconfont sui_icon_club_logo_fill_18px",staticStyle:{"color":"#873C00"}}):_vm._e(),_vm._v(" "),(_vm.item.apply_for == 101)?_c('span',{staticClass:"title",class:{
          'coupon-title__text_indent': [53, 55, 58, 59].includes(+_vm.item.real_type_id)
        },style:({'font-size': _vm.ruleLen > 2 && '12px'})},[_vm._v("\n        "+_vm._s(_vm.item.coupon_rule[0].value_amount.amountWithSymbol)+" "+_vm._s(_vm.language.SHEIN_KEY_PWA_25739)+"\n      ")]):[((_vm.item.real_type_id == '53' || _vm.item.real_type_id == '55' || _vm.item.apply_for == '9') && _vm.item.shipping_discount_type == 0)?_c('span',{staticClass:"title",class:{
            'coupon-title__text_indent': [53, 55, 58, 59].includes(+_vm.item.real_type_id),
          },style:({'font-size': _vm.ruleLen > 2 && '12px'})},[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_15807)+"\n        ")]):_c('span',{staticClass:"title",class:{
            'coupon-title__text_indent': [53, 55, 58, 59].includes(+_vm.item.real_type_id),
          },style:({'font-size': _vm.ruleLen > 2 && '12px'}),attrs:{"id":`title-${_vm.item.coupon}-${_vm.index}`,"tabindex":"0"}},[(['5', '6'].includes(_vm.item.apply_for))?[_vm._v("\n            "+_vm._s(_vm.language.SHEIN_KEY_PWA_15807)+"\n          ")]:(_vm.item.coupon_type_id == '1')?[_vm._v("\n            "+_vm._s(_vm.directionType == 'row'
                ? `- ${_vm.couponRule.value.amountWithSymbol}`
                : _vm.template(
                  _vm.couponRule.value.amountWithSymbol,
                  _vm.getTitleLanguage(_vm.couponRule)
                ))+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.template(
                _vm.percentFormat(_vm.couponRule.value.amount),
                _vm.getTitleLanguage(_vm.couponRule)
              ))+"\n          ")]],2)]],2),_vm._v(" "),((_vm.item.real_type_id == '53' || _vm.item.real_type_id == '55' || _vm.item.apply_for == '9') && _vm.item.shipping_discount_type == 1)?_c('span',{staticClass:"shiping-coupon-tip",class:{
        'is-green': _vm.isGreenTheme
      }},[_vm._v("\n      "+_vm._s(_vm.isGreenTheme ? _vm.language.SHEIN_KEY_PWA_29122 : _vm.language.SHEIN_KEY_PWA_22479)+"\n    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"coupon-title__labels"},[(_vm.isShowBest && _vm.item.is_best == 1 && (_vm.index + 1 ) == _vm.item.satisfied_range)?_c('span',{staticClass:"best-coupon"},[_c('i',{staticClass:"suiiconfont sui_icon_like_completed_16px"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.language.SHEIN_KEY_PWA_24708))])]):_vm._e(),_vm._v(" "),(_vm.item.coupon_rule.length <= 1 && _vm.stageable)?_c('span',{staticClass:"stackable-coupon-tip"},[_vm._v("\n        "+_vm._s(_vm.language.SHEIN_KEY_PWA_20999)+"\n      ")]):_vm._e()])]),_vm._v(" "),_c('p',{style:({'line-height':_vm.couponType == 'revisit-coupon' && '20px','color': _vm.IS_RW && !_vm.isGreenTheme ? '#fc4070' : ''})},[_vm._v("\n    "+_vm._s(_vm.couponSubTitle)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }