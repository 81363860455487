<template>
  <!-- 优惠券操作 -->
  <section class="btn-operation">
    <!-- 膨胀劵A 样式 -->
    <coupon-expansion
      v-if="item.expandStatus == 'btn' && expandAbtInfo?.CodeExpandType === 'A'"
      :text="language?.SHEIN_KEY_PWA_23621"
      :is-css-right="GB_cssRight"
      :sub-text="showExpansionDiscount"
      @click="expansionClick()"
    />

    <!-- 膨胀劵B 样式 -->
    <div
      v-if="item.expandStatus == 'btn' && expandAbtInfo?.CodeExpandType === 'B'"
      class="couppon-expansion-btn"
      :style="{ backgroundImage: `url(${PUBLIC_CDN}/pwa_dist/images/expansion_coupon/expansion-btn-yellow-8eb9ac396d.png)` }"
      @click="expansionClick()"
    >
      {{ language?.SHEIN_KEY_PWA_23621 }}
    </div>

    <!-- apply按钮 -->
    <s-button
      v-if="isShowApplyBtn"
      class="apply-btn"
      :type="['primary', 'H48PX']"
      @click="hanleApply"
    >
      {{ applyBtnLang }}
    </s-button>


    <!-- 膨胀盖章 -->
    <div
      v-if="(item.expandStatus == 'seal' && isloaded)"
      class="stamp-warpper loaded"
    >
      <div class="couppon-stamp">
        <div :style="{ backgroundImage: `url(${PUBLIC_CDN}/pwa_dist/images/expansion_coupon/${isExpansionBg ? 'expansion-stamp-red-95122b2a07.png':'expansion-stamp-ff514724ef.png'})` }">
          <span
            class="scale-font"
            :class="{ 'scale-font__limit': language.SHEIN_KEY_PWA_23693 && language.SHEIN_KEY_PWA_23693.length > 8 }"
          >
            {{ language.SHEIN_KEY_PWA_23693 }}
          </span>
        </div>
      </div>
    </div>

    <!-- 优惠券选择框 -->
    <s-radio
      v-if="showCouponCheckbox"
      :class="['coupon-check', { ar: GB_cssRight }]"
      :value="!!checked"
      :label="true"
      :reverse-select="true"
      :style="[ GB_cssRight ? { marginLeft: '0 !important' } : { marginRight: '0 !important' } ]"
    />

    <!-- expire按钮 -->
    <s-button
      v-if="isExpired"
      class="apply-btn expired-btn"
      :type="['primary', 'H48PX']"
      disabled
      block
    >
      {{ expiredType == 'used' ? language.SHEIN_KEY_PWA_30167 : language.SHEIN_KEY_PWA_15248 }}
    </s-button>

    <!-- 券已领取icon -->
    <div
      v-if="(item.coupon_status && item.coupon_status == '1' && page !== 'store') || isGetCouponSuccess"
      class="get-coupon-icon"
    >
      <div class="get-coupon-line">
        <div class="get-coupon-dashed">
          <div class="get-coupon-tip">
            {{ page === 'store' ? language.SHEIN_KEY_PWA_20959 : language.SHEIN_KEY_PWA_20958 }}
          </div>
        </div>
      </div>
    </div>

    <!-- 领券按钮 -->
    <s-button
      v-if="showGetBtn"
      class="apply-btn"
      :type="['primary', 'H48PX']"
      block
      :disabled="getCouponDisabled"
      @click="getCouponHandle"
    >
      {{ page == 'store' ? language.SHEIN_KEY_PWA_25528 : language.SHEIN_KEY_PWA_20964 }}
    </s-button>

    <!-- 券已领完-->
    <s-button
      v-if="item.coupon_status && item.coupon_status == '3'"
      class="apply-btn"
      :type="['primary', 'H48PX']"
      disabled
      block
    >
      {{ language.SHEIN_KEY_PWA_21004 }}
    </s-button>
    <!-- 券凑单，购物车券助手列表，指定优惠券的券面上新增凑单按钮 -->
    <s-button
      v-if="showAddonBtn"
      v-expose="{
        id: '1-8-2-21',
        data: {
          code: item.coupon
        }
      }"
      :type="['primary', 'H48PX']"
      @click="addOnClick"
    >
      <span v-if="addTobeAddMore">{{ language.SHEIN_KEY_PWA_25347 }}</span>
      <span v-else>{{ language.SHEIN_KEY_PWA_15091 }}</span>
      <!-- {{ language.SHEIN_KEY_PWA_15091 }} -->
    </s-button>

    <!-- Add more -->
    <s-button
      v-if="showAddonMoreBtn"
      v-expose="{
        id: '1-8-2-21',
        data: {
          code: item.coupon
        }
      }"
      :type="['primary', 'H48PX']"
      @click="handleAddClick(item)"
    >
      <!-- 凑单更多 -->
      {{ language.SHEIN_KEY_PWA_25347 }}
    </s-button>

    <!-- 在券助手中才显示 -->
    <s-button
      v-if="showAddBtn"
      v-expose="{
        id: '1-8-2-21',
        data: {
          code: item.coupon
        }
      }"
      :type="['primary', 'H48PX']"
      @click="handleAddClick(item)"
    >
      <!-- 凑单 -->
      <span>{{ language.SHEIN_KEY_PWA_15091 }}</span>
    </s-button>

    <!-- 个人中心店铺券凑单按钮 -->
    <s-button
      v-if="isShowStoreCouponAddBtn"
      class="apply-btn"
      :type="['primary', 'H48PX']"
      @click="hanleAdd"
    >
      {{ isUseLang ? language.SHEIN_KEY_PWA_18957 : language.SHEIN_KEY_PWA_17210 }}
    </s-button>

    <s-button
      v-if="showNewAddBtn"
      v-expose="{
        id: '1-8-2-21',
        data: {
          code: item.coupon
        }
      }"
      :type="['primary', 'H48PX']"
      @click="addOnClick"
    >
      <span v-if="addTobeAddMore">{{ language.SHEIN_KEY_PWA_25347 }}</span>
      <span v-else>{{ language.SHEIN_KEY_PWA_15091 }}</span>
    </s-button>

    <coupon-countdown
      v-if="showCouponCountdown"
      :class="{ 'RW-start-soon': item.timeStatus === '1' && page === 'userCoupon' }"
      :language="language"
      :item="item"
      :text="item.timeStatus === '1' ? language.SHEIN_KEY_PWA_27861 : ''"
      :direction-type="directionType"
      @countdown-over="countdownOver"
    />

    <!-- 支付成功按钮 -->
    <!-- <s-button
      v-if="isShowPaySuccessBtn"
      :type="['primary', 'H48PX']"
      class="head-row__top-wrapper-btn"
      @click="viewCouponList"
    >
      {{ language.SHEIN_KEY_PWA_17535 }} >
    </s-button> -->
  </section>
</template>

<script>
import { template } from '@shein/common-function'
const { host, langPath, GB_cssRight, PUBLIC_CDN, currency, lang, currencies } = gbCommonInfo
import { transformPromodiscountUrl } from 'public/src/pages/common/biz_helper/shein_picks'
import { bingCouponApi, estimatedBindCouponApi, isFreeShippingCoupon } from './utils'
import CouponExpansion from './couponExpansion.vue'
import CouponCountdown from './couponCountdown.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

// 是店铺券
const isStoreCoupon = coupon_dimension => Number(coupon_dimension) === 3
// 是自营店铺
const isSelfSupportStore = store_code => Number(store_code) === 1
// 券适用范围是全部商品
const isFullRange = apply_for => Number(apply_for) === 1
// 券适用范围是部分商品
const isPartRange = apply_for => Number(apply_for) === 2

/**
 * 购物车券助手 CART_COUPON_HELPER
 * 个人中心券列表 USER_COUPON_LIST
 * 
 * 其他场景: 下单页券列表，购物车领券 暂不支持凑单
 */
const SUPPORT_ADDON_SCENES = ['CART_COUPON_HELPER', 'USER_COUPON_LIST']

export default {
  name: 'CouponHandle',
  components: {
    CouponCountdown,
    CouponExpansion
  },
  props: {
    expandAbtInfo: {
      type: Object,
      default: () => {}
    },
    addonScene: {
      type: String,
      default: '',
    },
    usable: {
      type: Boolean,
      default: true
    },
    item: { // 优惠券信息
      type: Object,
      default: () => {},
    },
    language: { // 多语言
      type: Object,
      default: () => {},
    },
    isExpired: { // 是否失效
      type: Boolean,
      default: false
    },
    expiredType: {
      type: String,
      default: 'expired',
    },
    abtCouponApply: { // abt控制参数, 是否展示apply按钮, apply和use为凑单按钮
      type: Boolean,
      default: false
    },
    isLogin: { // 是否登录
      type: Boolean,
      default: false
    },
    isUseLang: { // 只控制apply按钮文案显示
      type: Boolean,
      default: false
    },
    isMultMall: { // 是否是多mall
      type: Boolean,
      default: true
    },
    showCouponCheckbox: { // 是否展示勾选框
      type: Boolean,
      default: false,
    },
    checked: { // 是否勾选
      type: Boolean,
      default: false,
    },
    isShowCountdownFlag: {
      type: Boolean,
      default: false
    },
    directionType: { // 多档券排列顺序
      type: String,
      default: 'column'
    },
    // isShowPaySuccessBtn: {
    //   // 是否展示支付成功按钮
    //   type: Boolean,
    //   default: false,
    // },
    isloaded: {
      // 盖章按钮是否添加样式
      type: Boolean,
      default: false,
    },
    isTitleEffectsCode: {
      type: String,
      default: ''
    },
    abtParams: {
      type: Object,
      default: () => {}
    },
    abtEstimated: { // abt控制参数, 是否命中到手价实验
      type: Boolean,
      default: false
    },
    page: {
      // 哪个页面的券列表
      type: String,
      default: '',
    },
    isExpansionCoupon: { // 是否展示膨胀按钮
      type: Boolean,
      default: false
    },
    isExpansionBg: { // 是否展示膨胀背景色
      type: Boolean,
      default: false
    },
    // 是否显示会费券的 apply 按钮
    duesCouponApplyVisible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      GB_cssRight,
      PUBLIC_CDN,
      getCouponDisabled: false, // loading, 避免多次点击
      isGetCouponSuccess: false, // 是否领取成功
      isGetAgain: false, // 是否再次领取
      isBtn: true, //是否展示膨胀按钮
      priceUnit: currencies?.[lang]?.[currency] || {},
    }
  },
  computed: {
    addTobeAddMore(){
      return this.usable && this.item.coupon_rule.length > 1 && Number(this.item.satisfied_range) < this.item?.coupon_rule?.length
    },
    // 控制去用券展示逻辑
    isShowApplyBtn() {
      // 会费券 apply 按钮展示判断
      if ([58, 59].includes(+this.item.real_type_id)) return this.duesCouponApplyVisible
      // wiki.pageId=1176264885
      if (this.isCouponaddall) {
        const { start_date, end_date } = this.item
        const current_date = new Date() / 1000
        return ['0', '2', '4'].includes(this.item.timeStatus) && start_date < current_date && current_date < end_date
      }
      if (this.item.real_type_id == '53') return false
      let isVerifiState = true
      if (this.item.timeStatus != '') {
        // 是否可用
        isVerifiState = ['0', '2'].includes(this.item.timeStatus)
      }
      if (this.page === 'store') {
        return this.item.coupon_status == '1' && isVerifiState && !this.isExpired
      } else {
        // 分享券活动按钮展示
        const applyForFlag = !['1', '5', '6', '8', '9'].includes(this.item.apply_for) && this.abtCouponApply
        // 店铺券不展示凑单按钮
        const isStoreCoupon = this.item.coupon_dimension != '3'
        
        return (
          isVerifiState
          && !this.isExpired
          && applyForFlag
          && isStoreCoupon
        )
      }
    },
    showCouponCountdown() {
      // 兼容旧逻辑
      if (this.page !== 'userCoupon') return this.isShowCountdownFlag && this.directionType != 'row'

      // 个人中心券列表未开始券展示倒计时
      return this.isShowCountdownFlag && (this.item.timeStatus === '1' || this.directionType != 'row')
    },
    // 是否展示凑单按钮add
    showAddBtn(){
      if (this.isCouponaddall) return false
      if(this.showInCart && this.item.coupon_dimension == 3 && ['1', '2'].includes(this.item.apply_for) && !this.usable && this.item?.store?.store_code != 1){
        return true
      }else {
        return false
      }
    },
    // 是否展示凑单按钮add more
    showAddonMoreBtn(){
      if (this.isCouponaddall) return false
      // 多档券
      if(this.item.coupon_rule.length > 1 && this.showInCart && this.item.coupon_dimension == 3 && ['1', '2'].includes(this.item.apply_for) && this.usable && Number(this.item.satisfied_range) < this.item?.coupon_rule?.length && this.item?.store?.store_code != 1){
        return true
      }else {
        return false
      }
      // return true
    },
    // 是否展示凑单按钮
    showAddonBtn() {
      if (this.isCouponaddall) return false
      if(this.abtParams?.Carthelperallactivityadd?.param?.carthelper_all_add == 'on' && this.item?.store?.store_code != 1 && this.item.apply_for == 1 && this.item.coupon_dimension == 1 && (this.item.coupon_type_id == 5 || this.item.coupon_type_id == 1) && Number(this.item.satisfied_range) < this.item.coupon_rule.length) {
        return true
      }
      return false
    },
    // 是否展示个人中心店铺券凑单按钮
    isShowStoreCouponAddBtn(){
      if (this.isExpired) return
      if (this.isCouponaddall) return false
      const pageIsUserCoupon = this.page === 'userCoupon'
      const {
        store: { store_code } = {},
        coupon_dimension,
        apply_for
      } = this.item
      return pageIsUserCoupon
      && isStoreCoupon(coupon_dimension)
      && !isSelfSupportStore(store_code)
      && (isFullRange(apply_for) || isPartRange(apply_for))
    },
    isCouponaddall() {
      return this.abtParams?.Couponaddall?.param?.coupon_add_all_switch == 'on'
    },
    /** 0912版本凑单入口，兼容所有券，abt控制，关闭则维持原凑单逻辑 */
    showNewAddBtn() {
      // 不在凑单场景 or abt关闭
      if (!SUPPORT_ADDON_SCENES.includes(this.addonScene) || !this.isCouponaddall) return false
      if (this.addonScene == 'CART_COUPON_HELPER') {
        if (!this.usable) {
          // 是邮费券 && 配置了门槛上限，券范围内的商品总金额高于门槛上限（券会归到不可用券列表，但是差额又是0）
          if (isFreeShippingCoupon(this.item) && this.item.reason == 8) {
            return false
          }
          return true
        }
        return Number(this.item.satisfied_range) < this.item.coupon_rule.length
      }
      return false
    },
    // apply 多语言取值
    applyBtnLang() {
      const { page, isUseLang, language } = this
      if (page === 'store') return language?.SHEIN_KEY_PWA_25720
      if (isUseLang) return language.SHEIN_KEY_PWA_18957
      return language.SHEIN_KEY_PWA_17210
    },
    // 膨胀后优惠值
    showExpansionDiscount() {
      if (this.item?.expandedCouponInfo?.ruleDimension === '1') {
        const value = this.item?.expandedCouponInfo?.rule?.[0].value.amountWithSymbol
        return `-${value}`
      }
      const amount = this.item?.expandedCouponInfo?.rule?.[0]?.value?.amount
      return this.language?.SHEIN_KEY_PWA_26235?.replace('{0}', `${parseInt(amount)}%`)
    },
    showGetBtn() {
      const { item, isGetCouponSuccess } = this
      return item?.coupon_status == '2' && !isGetCouponSuccess && item.is_bind !== 0
    }
  },
  watch: {
    isLogin (val) {
      if (val) {
        if (this.isGetAgain) {
          this.getCouponHandle()
        }
      }
    },
    item: {
      handler () {
        this.isGetAgain = false
        this.isGetCouponSuccess = false
      },
      deep: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      if ($(`#coupon-item-${this.item.keyId} .head-right`)?.height() > $(`#coupon-item-${this.item.keyId} .head-left`)?.height()) {
        $(`#coupon-item-${this.item.keyId} .head-right`).css('marginTop', '.2133rem')
      }
    })
  },
  methods: {
    template: template,
    hanleAdd(){
      const {
        store: { store_code } = {},
        apply_for,
        coupon,
        coupon_type_id,
      } = this.item
      if(isFullRange(apply_for)) {
        location.href = `${langPath}/store/home?store_code=${store_code}`
      }else {
        // 跳转部分商品用券弹框
        const query = `code=${coupon}&typeId=${coupon_type_id}`
        location.href = `${host}${langPath}/user/coupon/add-items?${query}`
      }
    },
    handleAddClick(item){
      // 点击埋点
      daEventCenter.triggerNotice({
        daId: '1-8-2-20',
        extraData: {
          code: this.item.coupon,
        }
      })
      const { apply_for } = item
      const store_code = this.item?.store?.store_code
      const {
        coupon,
        coupon_type_id,
      } = this.item
      if(apply_for == '1') {
        location.href = `${langPath}/store/home?store_code=${store_code}`
      }else {
        // 跳转部分商品用券弹框
        const query = `code=${coupon}&typeId=${coupon_type_id}`
        // this.$router.push(`${langPath}/user/coupon/add-items?${query}`)
        location.href = `${host}${langPath}/user/coupon/add-items?${query}`
      }
    },
    // 领取优惠券
    async getCouponHandle () {
      if (this.page === 'store') {
        this.$emit('clickBindCoupon', this.item)
        return
      }
      if (!this.isLogin) { // 判断是否登录
        this.isGetAgain = true
        this.$emit('login-handle')
        return
      }
      const params = {
        coupon_code: this.item?.coupon || '',
        mall_code: this.item?.mall?.mall_code || '',
        store_code: this.item?.store?.store_code || ''
      }
      const newParams = {
        coupon_codes: [this.item?.coupon || '']
      }
      this.getCouponDisabled = true
      if (this.abtEstimated) { // 此处命中到手价实验，调新接口
        const res = await estimatedBindCouponApi(newParams) // 绑定优惠券
        if (res.code == '0') {
          if (res.info?.failureList?.length) { // 绑定失败
            const errorCode = res.info.failureList[0].code
            this.$toast({ content: this.language[errorCode] })
          }
          if (res.info?.successList?.length) { // 绑定成功，券状态变为已领取(coupon_status == '1')
            this.$toast({ content: this.language.SHEIN_KEY_PWA_20962 })
            this.isGetCouponSuccess = true
          }
        }
      } else {
        const res = await bingCouponApi(params) // 绑定优惠券
        if (res.code == '0') {
          if (res.info?.failureList?.length) { // 绑定失败
            const errorCode = res.info.failureList[0].code
            this.$toast({ content: this.language[errorCode] })
          }
          if (res.info?.successList?.length) { // 绑定成功，券状态变为已领取(coupon_status == '1')
            this.$toast({ content: this.language.SHEIN_KEY_PWA_20962 })
            this.isGetCouponSuccess = true
          }
        }
      }
      
      this.getCouponDisabled = false
      this.$emit('get-coupon-handle', {
        code: this.item.coupon, // 券码
        result: this.isGetCouponSuccess, // 领券结果
        status: this.item.coupon_status
      })
    },
    joinStr (str = '', symbol = ',') {
      return str && str.join(symbol) || ''
    },
    // 去用券
    hanleApply () {
      if (this.page === 'store') {
        this.$emit('clickApplyCoupon', this.item)
        return
      }

      this.$emit('handle-apply')

      // 会费券点击 apply 跳转
      const targetRoute = {
        '58': `${langPath}/user/prime?productsource=myCouponCenter`,
        '59': `${langPath}/user/sheinsaver?productsource=myCouponCenter`,
      }[this.item.real_type_id]
      if (targetRoute) {
        this.$router.push(targetRoute)
        return
      }
      
      // $emit('handle-apply') 执行新的用券逻辑
      if (this.isCouponaddall) return

      const {
        apply_for,
        category_id = '',
        coupon,
        scId = '',
        coupon_type_id,
        id,
        coupon_status
      } = this.item
      const coupon_type = 1
      const catIds = this.joinStr(category_id)
      // const catIdsLine = this.joinStr(category_id, '-')
      const showTip = coupon_type_id == '2' ? 1 : 0
      let [mallCode, couponDimension] = ['', '']
      if (this.isMultMall) {
        mallCode = this.item?.mall?.mall_code || ''
        couponDimension = this.item?.coupon_dimension || ''
      }
      let query = ''
      switch (apply_for) {
        case '1':
          if (coupon_status) {
            this.$routerPush(transformPromodiscountUrl(`${langPath}/shein-picks.html`))
          }
          break
        case '2':
          // 跳转可用商品弹窗, 去页面内请求数据
          query = `code=${coupon}&typeId=${coupon_type_id}`
          // this.$router.push(`${langPath}/user/coupon/add-items?${query}`)
          location.href = `${host}${langPath}/user/coupon/add-items?${query}`
          break
        case '3':
          // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
          // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
          query = `ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`
          if (couponDimension == '2') {
            query += `&mallCode=${mallCode}`
          }
          sessionStorage.removeItem('closeCouponTip')
          location.href = `${host}${langPath}/coupon-product.html?${query}`
          break
        case '7':
          // 跳转选品页 scid
          // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
          query = `ShiwTip=1&CouponCode=${coupon.toLowerCase()}`
          if (couponDimension == '2') {
            query += `&mallCode=${mallCode}`
          }
          location.href = `${host}${langPath}/coupon-sc-${scId}.html?${query}`
          break
        default:
      }
    },
    countdownOver () {
      this.$emit('countdown-over', this.item.coupon)
    },
    viewCouponList () {
      this.$emit('view-coupon-list')
    },
    // 点击膨胀按钮
    expansionClick() {
      this.$emit('expansion-click', this.item)
    },
    addOnClick() {
      this.$emit('coupon-addon', this.item)
      daEventCenter.triggerNotice({
        daId: '1-8-2-20',
        extraData: {
          code: this.item.coupon,
        }
      })
    },
  }
}
</script>

<style lang="less">
.btn-operation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .coupon-check {
    margin-bottom: .16rem;
  }
  .apply-btn {
    margin-bottom: .16rem;
    background-color: #000;
    border-color: #000;
    border-radius: 0;
    & when(@IS_RW) {
      border-radius: 4/75rem;
    }
  }
}
.get-coupon-icon {
  width: 1.14rem;
  height: 1.14rem;
  border: 1px solid var(--themeColor, #FA6338);
  border-radius: 50%;
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .font-dpr(24px);
  font-weight: normal;
  transform: rotateZ(-30deg);
  .get-coupon-line {
    width: 100%;
    height: 100%;
    border: 1px solid var(--themeColor, #FA6338);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .get-coupon-dashed {
      background-color: var(--bgColor, #fff6f3);
      padding: 2px 0;
    }
    .get-coupon-tip {
      background: var(--bgColor, #fff6f3);
      height: .4rem;
      border-color: var(--themeColor, #FA6338);
      border-style: solid;
      line-height: .4rem;
      border-width: 1px 0 1px 0;
      min-width: 1.26rem;
      text-align: center;
    }
  }
}

// /*膨胀优惠卷新增样式 */
.couppon-expansion-btn {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-size: 0.32rem;
  color: #ffffd8;
  font-family: SF UI Display;
  font-weight: 700;
  padding: 0.1629rem 0.5186rem;
  animation: breathe 0.9s infinite;
  touch-action: none;
}
@keyframes breathe {
  0% {
    transform: scale(0.99);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(0.99);
  }
}

.stamp-warpper {
  max-width: 1.3866rem;
  margin: auto;
  backface-visibility: hidden;
}
.couppon-stamp {
  position: relative;
  touch-action: none;
  > div {
    background-repeat: no-repeat;
    background-size: cover;
    width: 1.3866rem;
    height: 1.3866rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: normal;
    color: "sui_color_promo";
    text-transform: uppercase;
    transform-origin: 50% 50%;
    transform: rotate(-15deg) scale(1);
    // transition: all 1.2s ease-in-out cubic-bezier(0.6, 0.04, 0.98, 0.335);
    // transition-delay: 1.5s;
    opacity: 0.5;
  }
  .scale-font {
    white-space: nowrap;
    font-size: 0.32rem;
    transform: scale(0.8);
    &__limit {
      transform: scale(0.5833);
    }
  }
}
.loaded .couppon-stamp > div {
  animation-name: fa;
	animation-duration: 0.5s;
  // transform: rotate(-15deg) scale(1);
}
@keyframes fa {
  0% { opacity: 0.4; transform: rotate(-15deg) scale(5);}
	100% { opacity: 0.5;transform: rotate(-15deg) scale(1);;}
}

.apply-not-time {

  &__entry {
    position: absolute;
  }
}

/* RW 未开始券倒计时 样式 */
.RW-start-soon {
  --sui-color-promo: #FC4070;
  /*rw:begin*/
  color: var(--sui-color-promo, #FC4070);

  .coupon-countdown__txt {
    /*rw:begin*/
    color: var(--sui-color-promo, #FC4070);
  }
  .coupon-countdown__time {
    span {
      /*rw:begin*/
      background-color: var(--sui-color-promo, #FC4070);
    }
  }
}

</style>
